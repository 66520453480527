.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* Upload component */

.mg20 {
  margin: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.container {
  position: relative;
  width: 600px;
  margin: auto;
}

.btn-upload {
  /* position: absolute !important; */
  /* right: 10px; */
  /* margin-left: 10px !important;
  margin-top: 10px !important; */
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 300px;
  vertical-align: middle;
  margin-top: 10px;
}

.list-group {
  padding: 0;
  margin: 0;
}

.list-header {
  margin-top: 10px !important;
}

.upload-message {
  font-weight: bold;
  color: #63A84E;
  margin-top: 20px !important;
}

.upload-message.error{
  color: #DA4148;
}

.btn-choose {
  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}
